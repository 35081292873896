/* auteur: Bellefeuille Ronald */
/* Date de cr�ation: 2011-03-11 */
function OuvreFerme(theid) {
	if (document.getElementById) {
		var switch_id = document.getElementById(theid);

		if (switch_id.className == 'show') {
			switch_id.className = 'hide'
		}
		else {
			switch_id.className = 'show'
		}
	}
}


/* Fontion utilis� pour les menus */
function showHide(theid, openclose) {
	if (document.getElementById) {
		var switch_id = document.getElementById(theid);

		if (openclose == '0') {
			switch_id.className = 'hide';
		}
		else {
			switch_id.className = 'show';
		}
	}
}

