/* auteur: Ronald Bellefeuille */
/* Date de cr�ation: 01-06-02 */

function cl (d, f1, f2, msg) 
{
	var envo1="<a href=mailto:";
	envo1 += d;
	envo1 +="@";
	envo1 += f1 + "." + f2 + ">";
	document.write(envo1+msg+"</a>");
}

function clm (d, f1, msg) 
{
	var envo1="<a href=mailto:";
	envo1 += d;
	envo1 +="@";
	envo1 += f1 + ">";
	document.write(envo1+msg+"</a>");
}


